




























































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import {
  EnableStatus,
  WarehouseCreateOrUpdateDto,
  WarehouseDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "WareHouseEdit",
})
export default class WareHouseEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  loading = true;
  show = false;
  form: WarehouseCreateOrUpdateDto = {};
  defaultData: WarehouseDto = {
    id: 0,
  };
  enableStatusArray: any = [
    { label: (this as any).$l.getLanguageText("basic.enable"), value: EnableStatus.Enable },
    { label: (this as any).$l.getLanguageText("basic.disable"), value: EnableStatus.Disable },
  ];
  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.dataId) {
        api.wareHouse
          .update({
            body: this.form,
          })
          .then(() => {
            this.show = false;
            this.$message.success((this as any).$l.getLanguageText("basic.updateSuccess").toString());
          });
      } else {
        api.wareHouse
          .create({
            body: this.form,
          })
          .then(() => {
            this.show = false;
            this.$message.success((this as any).$l.getLanguageText("basic.createSuccess").toString());
          });
      }
    });
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        api.wareHouse
          .get({ id: this.dataId })
          .then((res: WarehouseCreateOrUpdateDto) => {
            this.form = res!;
            console.log("form=" + this.form.status);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.defaultData };
        this.loading = false;
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.name"),
        trigger: "blur",
      },
    ],
  };

  get title() {
    if (this.dataId) {
      return (this as any).$l.getLanguageText("basic.edit").toString();
    } else {
      return (this as any).$l.getLanguageText("basic.create").toString();
    }
  }

  cancel() {
    this.show = false;
  }
}
