

















































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import {
  EnableStatus,
  WarehouseCreateOrUpdateDto,
  WarehouseLocationCreateOrUpdateDto,
  WarehouseLocationDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "LocationEdit",
})
export default class LocationEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  @Prop({ required: true })
  warehouseId!: number;

  show = false;
  loading = true;
  form: WarehouseLocationCreateOrUpdateDto = {
    warehouseId: 0,
  };
  defaultData: WarehouseLocationDto = {
    id: 0,
  };
  enableStatusArray: any = [
    { label: (this as any).$l.getLanguageText("basic.enable"), value: EnableStatus.Enable },
    { label: (this as any).$l.getLanguageText("basic.disable"), value: EnableStatus.Disable },
  ];

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      this.form.warehouseId = this.warehouseId;
      if (!valid) {
        return;
      }
      if (this.dataId) {
        api.wareHouseLocation
          .update({
            body: this.form,
          })
          .then(() => {
            this.show = false;
            this.$message.success((this as any).$l.getLanguageText("basic.updateSuccess").toString());
          });
      } else {
        api.wareHouseLocation
          .create({
            body: this.form,
          })
          .then(() => {
            this.show = false;
            this.$message.success((this as any).$l.getLanguageText("basic.createSuccess").toString());
          });
      }
    });
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        api.wareHouseLocation
          .get({ id: this.dataId })
          .then((res: WarehouseCreateOrUpdateDto) => {
            this.form = res!;
            // console.log("form=" + this.form.status);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.defaultData };
        this.loading = false;
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    frame: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.frame"),
        trigger: "blur",
      },
    ],
    layer: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.layer"),
        trigger: "blur",
      },
    ],
    position: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.position"),
        trigger: "blur",
      },
    ],
    status: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseLocation.tips.status"),
        trigger: "blur",
      },
    ],
  };

  get title() {
    if (this.dataId) {
      return (this as any).$l.getLanguageText("basic.edit").toString();
    } else {
      return (this as any).$l.getLanguageText("basic.create").toString();
    }
  }

  cancel() {
    this.show = false;
  }
}
